import { useState } from "react";

import getBrowserLocale from "../utils/getBrowserLocale";

const useBrowserLocale = (supportedLocales = ["en"]) => {
  const [browserLocale] = useState(getBrowserLocale(supportedLocales));
  return browserLocale;
};

export default useBrowserLocale;
