const getBrowserLocale = (supportedLocales = ["en"]) => {
  const languages: string[] = [];

  if ("userLanguage" in window.navigator)
    languages.push((window.navigator as any).userLanguage);
  if (window.navigator.language) languages.push(window.navigator.language);
  if (window.navigator.languages) languages.push(...window.navigator.languages);

  for (const language of languages) {
    if (supportedLocales.indexOf(language) >= 0) return language;

    if (/^[a-z]{2}-[A-Z]{2}$/.test(language)) {
      const locale = language.substr(0, 2);
      if (supportedLocales.indexOf(locale) >= 0) return locale;
    }
  }

  return supportedLocales[0];
};

export default getBrowserLocale;
